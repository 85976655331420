import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layoutWithContainer.js";
import SEO from "../components/seo";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="VPS Affiliate Program" mdxType="SEO" />
    <h1 {...{
      "id": "vps-affiliate-program"
    }}>{`VPS Affiliate Program`}</h1>
    <hr />
    {
      /* ## Table of contents ## */
    }
    {
      /* ## Description ## */
    }
    <p>{`Start selling VPS as an affiliate through our `}<OutboundLink href="https://portal.hattonenterprisesolutions.uk/affiliates.php" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">{`Portal`}</OutboundLink>{`.`}</p>
    <Link to="contact-us" mdxType="Link">Contact Us</Link> for more information.
    <br /><br />
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/VikrV0Zq5X4",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      